import React from 'react'

// Images
import SSBArrowRight from 'img/SSB_arrow_right.svg'

// Components
import ButtonDefault from 'components/elements/ButtonDefault'

// Third Party
import styled from 'styled-components'
import parse from 'html-react-parser'

const StyledOverSSB = styled.div`
  h2 {
    font-size: ${props => props.theme.font.size.xxml};

    @media screen and (max-width: 991px) {
      font-size: ${props => props.theme.font.size.xxl};
    }
  }
`

const ContentContainer = styled.div`
  p {
    color: ${props => props.theme.color.text.secondary};
    line-height: 30px;
    text-align: center;
    font-weight: ${props => props.theme.font.weight.s};
  }
`

const StyledButtonDefault = styled(ButtonDefault)`
  p {
    white-space: nowrap;
    font-size: ${props => props.theme.font.size.m};
  }
`

const OverSSB = ({ className, content }) => {
  return (
    <StyledOverSSB className={`${className ? `${className}` : ``}`}>
      <h2 className="text-center pb-lg-5 font-weight-xl">{content.title}</h2>
      <ContentContainer className="px-lg-5">
        {parse(content.text)}
      </ContentContainer>
      <div className="d-flex justify-content-center py-3">
        <StyledButtonDefault to={content.link.url} className="py-1 px-1">
          <p className="mb-0 font-weight-xl">{content.link.title}</p>
          <img className="ml-3" src={SSBArrowRight} alt="" />
        </StyledButtonDefault>
      </div>
    </StyledOverSSB>
  )
}

export default OverSSB