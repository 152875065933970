import React from 'react'
import Img from 'gatsby-image'

// Images
import SSBArrowRight from 'img/SSB_arrow_right.svg'

// Components
import ButtonDefault from 'components/elements/ButtonDefault'

// Third Party
import styled, { css } from 'styled-components'
import parse from 'html-react-parser'

const StyledProject = styled.div``

const Context = styled.div`
  h2 {
    font-size: ${props => props.theme.font.size.xxml};
    padding-left: 100px;

    @media screen and (max-width: 991px) {
      padding-left: 0;
    }

    @media screen and (max-width: 575px) {
      font-size: ${props => props.theme.font.size.xxl};
    }
  }

  p {
    font-weight: ${props => props.theme.font.weight.s};
    line-height: 30px;
    color: ${props => props.theme.color.text.secondary};
    padding-bottom: 50px;
    padding-left: 100px;

    @media screen and (max-width: 991px) {
      padding-left: 0;
      padding-bottom: 0;
    }
  }
`

const StyledButtonDefault = styled(ButtonDefault)`
  p {
    font-weight: ${props => props.theme.font.weight.xl};
    font-size: ${props => props.theme.font.size.m};
    color: ${props => props.theme.color.text.light};
    padding-bottom: 0;
    padding-left: 0;
    white-space: nowrap;
  }
`

const BlueBox = styled.div`
  background: ${props => props.theme.color.gradient_background};
  background: ${props => props.theme.color.gradient};

  height: 460px;
  width: 460px;

  h2 {
    position: relative;

    left: 20px;
    top: 15px;
  }

  @media screen and (max-width: 575px) {
    height: 300px;
    width: 300px;
  }

  @media screen and (max-width: 382px) {
    height: 300px;
    width: 300px;

    h2 {
      font-size: ${props => props.theme.font.size.l};
    }
  }

  @media screen and (max-width: 340px) {
    height: 250px;
    width: 250px;
  }
`

const StyledImg = styled(Img)`
  position: relative;

  top: 25px;
  left: 20px;
`

const StyledBackgroundImg = styled(Img)`
  position: absolute !important;
  height: 125%;
  width: 1000px;
  z-index: -1;
  top: -70px;

  ${props => props.left && css`
    left: -300px;
  `}

  ${props => props.right && css`
    right: -300px;
  `}

  @media screen and (max-width: 991px) {
    height: 250%;
  }

  @media screen and (max-width: 575px) {
    height: 200%;
  }

  @media screen and (max-width: 382px) {
    height: 170%;
  }
`

const Project = ({ className, project }) => {
  return (
    <StyledProject className={`${className ? `${className}` : ``}`}>
      <div className="row position-relative">
        <Context className="col-lg-6 px-lg-5">
          <h2 className="font-weight-xl">{project.title}</h2>
          {parse(project.text)}
          <div className="d-flex justify-content-end pr-lg-4">
            <StyledButtonDefault className="py-1 px-1" to={project.link.url}>
              <p className="mb-0">{project.link.title}</p>
              <img className="ml-3" src={SSBArrowRight} alt="" />
            </StyledButtonDefault>
          </div>
          <StyledBackgroundImg left fluid={project.image.localFile.childImageSharp.fluid} alt="" />
        </Context>
        <div className="col-lg-6 pt-lg-0 pt-5 d-flex justify-content-center justify-content-lg-start">
          <BlueBox>
            <h2 className="text-uppercase color-text-light font-weight-xl">{project.relation[0].acf.info.title}</h2>
            <StyledImg fluid={project.relation[0].acf.images.small_images.localFile.childImageSharp.fluid} alt="" />
          </BlueBox>
        </div>
      </div>
    </StyledProject>
  )
}

export default Project