/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'

// Template
import HomeTemplate from 'templates/home.js'

export const pageQuery = graphql`
  {
    page: wordpressPage(template: {eq: "index.php"}) {
      ...HomeFrag
    }
  }
`

const Home = ({data}) => {
  return (
    <HomeTemplate data={data} />
  )
}

export default Home