/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import Hero from 'components/shared/Hero'
import Services from 'components/shared/Services'
import Project from 'components/home/Project'
import OverSSB from 'components/home/OverSSB'

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      ...HomeFrag
    }
  }
`

const HomeTemplate = ({
  data: {
    page: {
      path,
      acf: {
        banner,
        projecten,
        over_ssb: Over
      },
      yoastMeta
    },
  },
}) => {
  return (
    <Layout>
      <SEO yoast={{ meta: yoastMeta }} pathname={path} article />

      <Hero home className="mb-5" content={banner} />

      <div className="container py-5">
        <Services />
        <Project className="pb-5" project={projecten} />
        <div className="py-5" />
        <OverSSB className="py-5" content={Over} />
      </div>
    </Layout>
  )
}

export default HomeTemplate
